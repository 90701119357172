import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import darkLogo from './../../Assets/Images/ZURAH-LOGO.png';

const TitleMetaTags = () => {
  const [metaData, setMetaData] = useState({
    title: '',
    description: '',
    keywords: '',
    image: darkLogo,
  });

  const location = useLocation();
  // console.log(window.location.pathname);

  const metaConfig = {
    '/': {
      // title: 'Fine Jewelry & Diamond Collections | Zurah Jewellery',
      title: 'Upqor Education Hub',
      // keywords: 'Lab-grown diamonds, Certified lab-grown diamonds, Chemically identical diamonds, Sustainable diamonds, Eco-friendly diamonds, Lab-grown diamond properties, Brilliant lab-grown diamonds, Sparkling lab-grown diamonds, Engagement rings collection, Wedding ring collection, Luxury earrings collection, Diamond pendant collection, Fine jewelry collections, Most loved jewelry, Popular diamond rings, Jewelry worn on the finger, Earrings, neck rings, arm rings, Popular jewelry pieces, Handcrafted diamond jewelry, Lab-grown diamond rings, Modern crafted jewelry, Fine diamond necklaces, Custom diamond earrings, Wedding jewelry collection, Bridal jewelry, Elegant wedding rings, Wedding accessories, Diamond investment value, Diamond jewelry as investment, Best diamond investments, Gold and diamond jewelry for festivals, Customer engagement moments, Engagement photos, Real engagement stories, Worldwide engagement celebrations, Customer jewelry moments',
      // description:
      //   'Zurah Jewellery Handcrafted engagement rings, stunning wedding bands, and premium fine jewelry. Celebrate life special moments with elegance and brilliance.',
      image: darkLogo,
    },
    '/products/JEWEL': {
      // title: 'Latest Gold & Diamond Jewellery Collection | Zurah Jewellery',
    },

  };

  useEffect(() => {
    const pathname = location.pathname;

    const formatSegment = (segment) => {
      const cleanedSegment = segment.replace(/-pv\w+$/, '');
      return cleanedSegment
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    };

    const generateDynamicMeta = (path) => {
      if (metaConfig[path]) {
        return metaConfig[path];
      }

      const segments = path.split('/').filter((segment) => segment);

      // Skip specific segments like 'products', 'type', and 'JEWEL'
      const skipSegments = ['products', 'JEWEL', 'type'];
      const relevantSegments = segments.filter(segment => !skipSegments.includes(segment));

      // Format the remaining segments
      const formattedSegments = relevantSegments.map((segment, index) => formatSegment(segment));

      // Join them with ' - '
      // const dynamicTitle = `${formattedSegments.join(' - ')} | Zurah Jewellery`;
      // const dynamicTitle = `${formattedSegments.join(' - ')} | Upqor Education Hub`;

      return {
        // title: dynamicTitle,
        // description: 'Zurah Jewellery Handcrafted engagement rings, stunning wedding bands, and premium fine jewelry. Celebrate life special moments with elegance and brillianc',
        // keywords: 'Lab-grown diamonds, Certified lab-grown diamonds, Chemically identical diamonds, Sustainable diamonds, Eco-friendly diamonds, Lab-grown diamond properties, Brilliant lab-grown diamonds, Sparkling lab-grown diamonds, Engagement rings collection, Wedding ring collection, Luxury earrings collection, Diamond pendant collection, Fine jewelry collections, Most loved jewelry, Popular diamond rings, Popular gold rings, Jewelry worn on the finger, Earrings, neck rings, arm rings, Popular jewelry pieces, Handcrafted diamond jewelry, Lab-grown diamond rings, Modern crafted jewelry, Fine diamond necklaces, Custom diamond earrings, Wedding jewelry collection, Bridal jewelry, Elegant wedding rings, Wedding accessories, Diamond investment value, Diamond jewelry as investment, Best diamond investments, Gold and diamond jewelry for festivals, Customer engagement moments, Engagement photos, Real engagement stories, Worldwide engagement celebrations, Customer jewelry moments',
        image: darkLogo,
      };
    };

    const currentMeta = generateDynamicMeta(pathname);
    setMetaData(currentMeta);
  }, [location]);

  return (
    <Helmet>
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description} />
      <meta name="keywords" content={metaData.keywords} />
      <meta property="og:title" content={metaData.title} />
      <meta property="og:image" content={metaData.image} />
      <meta property="og:description" content={metaData.description} />
      <meta name="twitter:title" content={metaData.title} />
      <meta name="twitter:description" content={metaData.description} />
      <meta name="twitter:image" content={metaData.image} />
      <meta name="twitter:card" content="summary_large_image" />

      {/* <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "name": metaData.title || "Zurah Jewellery",
          "url": "https://uat.zurahjewellery.com" + location.pathname,
          "logo": metaData.image,
          "description": metaData.description,
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "15205 North Kierland Blvd, Suite 100, Scottsdale",
            "addressLocality": "Scottsdale",
            "addressRegion": "Arizona",
            "postalCode": "85254",
            "addressCountry": "US",
          },
          "telephone": "+1 9876543210",
          "openingHours": ["Mo-Fr 09:00-18:00"],
        })}
      </script> */}
    </Helmet>
  );
};


export default TitleMetaTags;
