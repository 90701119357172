import React, { useState, useEffect } from "react";
import "./../src/Assets/css/bootstrap.min.css";
import './App.scss';
import "./icon.scss";
import "./Assets/css/animate.min.css"
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import Commanservice, { domain } from "./CommanService/commanService";
import { loginModal, headerLoginModal, FooterLoginModal, DefaultBillingAddress, storeFavCount, countCart, storeEntityId, storeCurrency, sliderAlignment } from "./Redux/action";
import Header from './Components/HeaderFooter/Header/header';
import { RouteComponent } from './Routes/routes';
import { Routes, Route, useLocation } from "react-router-dom";
import Footer from './Components/HeaderFooter/Footer/footer';
import Notification from "./CommanUIComp/Notification/Notification";
import { isEmpty, RandomId } from "./CommanFunctions/commanFunctions";
import Offcanvas from 'react-bootstrap/Offcanvas';
import BreadcrumbModule from "./CommanUIComp/Breadcrumb/breadcrumb";
import PageUnderConstruction from "./CommanUIComp/PageUnderConstruction/pageUnderConstruction";
import CampaignPreview from "./Components/CampaignPreview/campaignPreview";
import TitleMetaTags from './Components/TitleMetaTags/TitleMetaTags';

function App() {

  const [customizeshow, customizeshowSetShow] = useState(false);

  const customizerClose = () => customizeshowSetShow(false);
  const customizerShow = () => customizeshowSetShow(true);

  const [activePreset, setActivePreset] = useState('theme-1');
  const handlePresetClick = (preset) => {
    sessionStorage.setItem('presetColor', preset);
    setActivePreset(preset);
  };

  const selector = useSelector(state => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const isLogin = Object.keys(selector.loginData).length > 0;

  // Toast Msg
  const [toastShow, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isPageSiteOpen, setIsPageSiteOpen] = useState(false);

  // Stap by Stap API
  const [callAPI, setCallAPI] = useState(false);
  const [storeTemplateData, setStoreTemplateData] = useState([]);
  const [themeIcon, setThemeIcon] = useState('0');
  const isJewelDiy = location.pathname.includes("start-with-a-setting");
  const isDiamoDiy = location.pathname.includes("start-with-a-diamond");
  const isCreateWithZurah = location.pathname.includes("start-with-diamond");

  const storeTemplate = (resObj) => {
    const storeDataObj = resObj || {};
    const obj = {
      a: "PublishTemplate",
      SITDeveloper: "1",
      secret_key: storeDataObj.secret_key,
      transaction_id: storeDataObj.transaction_b2c_id,
      host: storeDataObj?.store_domain,
      preview: '0',
    }
    Commanservice.postApi("/TemplateMaster", obj).then((res) => {
      if (res.data.success === 1) {
        if (res.data.data) {
          setStoreTemplateData(res.data.data)
          var data = res.data.data;
          const detailJson = JSON.parse(data.publish_json);
          if (detailJson) {
            if (isEmpty(detailJson['accent_colors']) !== "") {
              sessionStorage.setItem('presetColor', detailJson['accent_colors']);
              setActivePreset(detailJson['accent_colors']);
            }
          } else {
            setActivePreset(activePreset);
          }
        }
      }
    }).catch(() => {
      setIsPageSiteOpen(true);
    })
  }

  const fetchPublishTemplate = (responseData) => {
    if (storeTemplateData.length === 0) {
      storeTemplate(responseData);
    }
  }

  const getStoreData = useCallback(() => {
    const getEntityId = {
      a: "GetStoreData",
      store_domain: domain,
      SITDeveloper: "1",
    };
    Commanservice.postApi('/EmbeddedPageMaster', getEntityId).then((res) => {
      if (res.data.success === 1) {
        const data = res.data.data;

        fetchPublishTemplate(data);
        if (Object.keys(data).length > 0) {
          favouriteCartCount(data);
          // templateData(data)
          dispatch(storeEntityId(data));
          // document.title = res.data.data.store_name;
          sessionStorage.setItem("storeData", JSON.stringify(data));
          dispatch(storeCurrency(data.store_currency));
        }
      } else {
        sessionStorage.setItem("storeData", false)
        setToastOpen(true);
        setIsSuccess(false);
        setToastMsg(res.data.message);
      }
    }).catch(() => { sessionStorage.setItem("storeData", false) })
  }, [dispatch])

  const favouriteCartCount = (data) => {
    const obj = {
      "a": "get_count",
      "store_id": data !== undefined ? data.mini_program_id : selector.storeEntityId.mini_program_id,
      "user_id": isLogin ? selector.loginData.member_id : RandomId,
    }
    Commanservice.postLaravelApi("/CartMaster", obj).then((res) => {
      if (res.data.success === 1) {
        if (Object.keys(res.data.data).length > 0) {
          dispatch(storeFavCount(res.data.data?.favourite_count));
          dispatch(countCart(res.data.data?.cart_count));
          setCallAPI(true);
          if (selector.DefaultBillingAddress.length === 0) {
            if (isLogin) {
              const Address = {
                a: "GetBilling",
                user_id: isLogin ? selector.loginData.member_id : RandomId,
                store_id: selector.storeEntityId.mini_program_id,
                status: "1",
                per_page: "0",
                number: "0"
              }
              Commanservice.postLaravelApi('/BillingDetails', Address).then((res) => {
                if (res.data.success == 1) {
                  var billingData = res.data.data
                  if (billingData.length > 0) {
                    for (let c = 0; c < billingData.length; c++) {
                      if (billingData[c].status === 1) {
                        dispatch(DefaultBillingAddress(billingData[c]))
                      }
                    }
                  }
                }
              })
            }
          }
        }
      } else {
        setIsSuccess(false);
        setToastOpen(true);
        dispatch(storeFavCount(0));
        dispatch(countCart(0));
        setToastMsg(res.data.message);
      }
    }).catch(() => {
      dispatch(storeFavCount(0));
      dispatch(countCart(0));
    })
  };


  useEffect(() => {
    if (window.location.href.includes('themeIcon')) {
      setThemeIcon(window.location.href.split('&themeIcon=')[1]);
    }

    if (sessionStorage.getItem('presetColor')) {
      const preset = sessionStorage.getItem('presetColor');
      setActivePreset(preset);
      document.documentElement.className = preset;
    }

    var storeData = JSON.parse(sessionStorage.getItem("storeData"));
    if (storeData !== null && storeData !== undefined && storeData !== false) {
      favouriteCartCount(storeData);
    } else {
      getStoreData();
    }
    dispatch(loginModal(false));
    dispatch(headerLoginModal(false));
    dispatch(FooterLoginModal(false));

    var count = 0;
    var count2 = 0;
    setInterval(() => {
      // Product Height
      var className2 = document.querySelector(".product-img-separate");
      if (className2 !== null && className2 !== undefined && className2 !== "") {
        var divElement2 = document.querySelector(".product-img-separate");
        if (divElement2.getBoundingClientRect() !== null && divElement2.getBoundingClientRect() !== undefined) {
          var elemRect2 = divElement2.getBoundingClientRect();
          var elemHeight2 = elemRect2.width;
          if (elemHeight2 !== 0) {
            var height2 = document.getElementsByTagName('figure');
            if (height2.length > count2) {
              height2[count2].setAttribute("style", `height:${elemHeight2 + "px"};`);
              count2++;
            } else {
              count2 = 0;
            }
          }
        }
      }

      // Skeleton Height
      var className = document.getElementsByClassName("Skeleton");
      if (className !== null && className !== undefined && className.length > 0) {
        var divElement = document.querySelector(".Skeleton");
        if (divElement.getBoundingClientRect() !== null && divElement.getBoundingClientRect() !== undefined) {
          var elemRect = divElement.getBoundingClientRect();
          var elemHeight = elemRect.width;
          var height = document.getElementsByClassName('.Skeleton');
          if (height.length > count) {
            height[count].setAttribute("style", `height:${elemHeight + "px"};`);
            count++;
          } else {
            count = 0;
          }
        }
      }

      // loader hidden
      var loader = document.getElementById("loader");
      var body = document.getElementById("body");
      var active = document.getElementsByClassName("navbar-toggler active");
      var lgactive = document.getElementsByClassName('product-detail_right');
      if (loader !== null || active.length > 0) {
        body.setAttribute("style", "overflow:hidden;");
        if (lgactive.length == 0) {
          window.scrollTo(0, 0);
        }
      } else {
        if (active.length === 0) {
          body.setAttribute("style", "overflow:visible;")
        }
      }
    }, 1);
  }, [activePreset]);

  const saveAsDraft = (value1, value2) => {
    const json = {
      accent_colors: activePreset
    }
    var selector = JSON.parse(sessionStorage.getItem('storeData'));
    const obj = {
      a: "saveTemplateCustomizedData",
      SITDeveloper: "1",
      tenant_id: selector.tenant_id,
      entity_id: selector.entity_id,
      member_id: selector.customer_id,
      template_id: isEmpty(storeTemplateData.template_id),
      module: '5',
      publish_status: value1,
      save_draft_status: value2,
      unique_id: isEmpty(storeTemplateData.unique_id),
      preview_url: isEmpty(selector.store_domain),
      publish_url: isEmpty(selector.store_domain),
      lang_id: isEmpty(storeTemplateData.lang_id),
      transaction_id: isEmpty(storeTemplateData.transaction_id),
      json_contents: JSON.stringify(json),
      secret_key: selector.secret_key,
    }
    Commanservice.postApi("/TemplateMaster", obj).then((res) => {
      if (res.data.success === 1) {
        customizeshowSetShow(false);
        var storeData = JSON.parse(sessionStorage.getItem("storeData"));
        if (isEmpty(storeData) != '') {
          storeTemplate(storeData);
        }
      }
    });
  }


  return (
    <>
      <TitleMetaTags />
      {!isPageSiteOpen ?
        <>
          {callAPI && <Header />}

          {isJewelDiy != true && isDiamoDiy != true && isCreateWithZurah != true ?
            < BreadcrumbModule /> : ''
          }

          <Routes>


            {RouteComponent.map((route, index) => {
              return <Route
                key={index}
                path={route.path}
                Component={route.component}
                element={route.element}
              />
            }
            )}

            {/* <Route path="/campaign/:campaign_id/:un_id?" element={<CampaignPreview />} /> */}
          </Routes>
          <Footer />

        </>
        :
        <PageUnderConstruction />
      }
      {themeIcon == '1' ? <>
        <div className="customizerBtn" onClick={customizerShow}>
          <i className="ic_setting"></i>
        </div>
      </> : ''}
      <Offcanvas show={customizeshow} onHide={customizerClose} placement={'end'}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5 className="mb-0">Theme Customize</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="theme-title">Theme Colors</div>
          <div className="theme-color">
            <a className={activePreset === 'theme-1' ? 'active' : ''} data-value="theme-1" onClick={() => handlePresetClick('theme-1')}>
              <div className="circle-box">
                <div className="colors-box">
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                </div>
              </div>
            </a>
            <a className={activePreset === 'theme-2' ? 'active' : ''} data-value="theme-2" onClick={() => handlePresetClick('theme-2')}>
              <div className="circle-box">
                <div className="colors-box">
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                </div>
              </div>
            </a>
            <a className={activePreset === 'theme-3' ? 'active' : ''} data-value="theme-3" onClick={() => handlePresetClick('theme-3')}>
              <div className="circle-box">
                <div className="colors-box">
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                </div>
              </div>
            </a>
            <a className={activePreset === 'theme-4' ? 'active' : ''} data-value="theme-4" onClick={() => handlePresetClick('theme-4')}>
              <div className="circle-box">
                <div className="colors-box">
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                </div>
              </div>
            </a>
            <a className={activePreset === 'theme-5' ? 'active' : ''} data-value="theme-5" onClick={() => handlePresetClick('theme-5')}>
              <div className="circle-box">
                <div className="colors-box">
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                </div>
              </div>
            </a>
            <a className={activePreset === 'theme-6' ? 'active' : ''} data-value="theme-6" onClick={() => handlePresetClick('theme-6')}>
              <div className="circle-box">
                <div className="colors-box">
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                </div>
              </div>
            </a>
            <a className={activePreset === 'theme-7' ? 'active' : ''} data-value="theme-7" onClick={() => handlePresetClick('theme-7')}>
              <div className="circle-box">
                <div className="colors-box">
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                </div>
              </div>
            </a>
            <a className={activePreset === 'theme-8' ? 'active' : ''} data-value="theme-8" onClick={() => handlePresetClick('theme-8')}>
              <div className="circle-box">
                <div className="colors-box">
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                </div>
              </div>
            </a>
          </div>
        </Offcanvas.Body>
        <div className="Offcanvas-footer">
          <button className="btn" onClick={() => saveAsDraft('1', '0')}>Publish</button>
        </div>
      </Offcanvas>
      <Notification toastMsg={toastMsg} toastShow={toastShow} isSuccess={isSuccess} Close={() => setToastOpen()} />
    </>
  );
}

export default App;
